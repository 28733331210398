<template>
	<NuxtImg
		:alt="alt"
		:src="src"
		class="service-image"
		:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
		loading="lazy"
	/>
</template>
<script setup lang="ts">
import type { CssSize, ResponsiveSizeOrAuto } from '@SHARED/utils/helperTypes';

import { DEFAULT_NUXT_IMAGE_PLACEHOLDER } from '@SHARED/utils/style';

defineOptions({ name: 'ServiceImage' });

type Props = {
	alt: string;
	src: string;
	height?: ResponsiveSizeOrAuto | null;
	width?: ResponsiveSizeOrAuto | null;
};

const props = withDefaults(defineProps<Props>(), {
	height: null,
	width: null
});

const desktopMaxHeight = computed<CssSize | 'auto'>(
	() => props.height?.desktop ?? '400px'
);

const tabletMaxHeight = computed<CssSize | 'auto'>(
	() => props.height?.tablet ?? '300px'
);

const mobileMaxHeight = computed<CssSize | 'auto'>(
	() => props.height?.mobile ?? '300px'
);

const desktopMaxWidth = computed<CssSize | 'auto'>(
	() => props.width?.desktop ?? '100%'
);

const tabletMaxWidth = computed<CssSize | 'auto'>(
	() => props.width?.tablet ?? '100%'
);

const mobileMaxWidth = computed<CssSize | 'auto'>(
	() => props.width?.mobile ?? '100%'
);
</script>

<style lang="scss" scoped>
.service-image {
	height: v-bind(mobileMaxHeight);
	width: v-bind(mobileMaxWidth);

	@include screen-up(md) {
		height: v-bind(tabletMaxHeight);
		width: v-bind(tabletMaxWidth);
	}

	@include screen-up(lg) {
		height: v-bind(desktopMaxHeight);
		width: v-bind(desktopMaxWidth);
	}
}
</style>
