<template>
	<div :class="{ odd: isOddRow }" class="service container">
		<ServiceImage
			:alt="title"
			:src="image"
			:height="imageSizes?.height"
			:width="imageSizes?.width"
			class="service-image"
		/>

		<div class="service-text-container">
			<span class="service-title heading-font">
				{{ title }}
			</span>
			<span v-if="subtitle" class="service-subtitle default-font">
				<ParagraphsWithLineBreak :text="subtitle" />
			</span>
			<div v-if="description" class="service-description default-font">
				<ParagraphsWithLineBreak :text="description" />
			</div>
			<div v-if="buttons" class="buttons-container">
				<ButtonBlock
					v-for="(button, bIndex) in buttons"
					:key="`services-listing-section-button:${bIndex}:${button.text}`"
					v-bind="button"
				/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { CompanyProvidedService } from '@SHARED/core/entities/sections/ServicesListingSection';
import type { WebsiteStyles, Color } from '@SHARED/core/entities/WebsiteConfig';
import type { CssSize } from '@SHARED/utils/helperTypes';
import { getCSSVar } from '@SHARED/utils';

import ParagraphsWithLineBreak from '@SHARED/components/atoms/ParagraphsWithLineBreak.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import ServiceImage from '@/components/atoms/ServiceImage.vue';

defineOptions({ name: 'ServiceCard' });

const styles = useState<WebsiteStyles>('styles');

export type ServiceCardProps = CompanyProvidedService & {
	isOddRow: boolean;
	itemGap?: CssSize | null;
	textColor?: Color | null;
	backgroundColor?: Color | null;
};

const props = withDefaults(defineProps<ServiceCardProps>(), {
	gapBetweenImageAndText: null,
	gapBetweenTextItems: null,
	imageSizes: null,
	buttons: () => [],
	fontSizes: null,
	fontWeights: null,
	itemGap: null,
	textColor: null,
	backgroundColor: null
});

const mobileServicesGapBetweenImageAndText = computed<CssSize>(
	() => props.gapBetweenImageAndText?.mobile || '2rem'
);

const tabletServicesGapBetweenImageAndText = computed<CssSize>(
	() => props.gapBetweenImageAndText?.tablet || '2rem'
);

const desktopServicesGapBetweenImageAndText = computed<CssSize>(
	() => props.gapBetweenImageAndText?.desktop || '2rem'
);

const mobileServicesGapBetweenTextItems = computed<CssSize>(
	() => props.gapBetweenTextItems?.mobile || '1rem'
);

const tabletServicesGapBetweenTextItems = computed<CssSize>(
	() => props.gapBetweenTextItems?.tablet || '1rem'
);

const desktopServicesGapBetweenTextItems = computed<CssSize>(
	() => props.gapBetweenTextItems?.desktop || '1.5rem'
);

const mobileServicesTitleFontSize = computed<CssSize>(
	() => props.fontSizes?.servicesTitle?.mobile || '2rem'
);

const tabletServicesTitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesTitle?.tablet || mobileServicesTitleFontSize.value
);

const desktopServicesTitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesTitle?.desktop || tabletServicesTitleFontSize.value
);

const servicesTitleFontWeight = computed<number>(
	() => props.fontWeights?.servicesTitle || 500
);

const mobileServicesSubtitleFontSize = computed<CssSize>(
	() => props.fontSizes?.servicesSubtitle?.mobile || '1.125rem'
);

const tabletServicesSubtitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesSubtitle?.tablet ||
		mobileServicesSubtitleFontSize.value
);

const desktopServicesSubtitleFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesSubtitle?.desktop ||
		tabletServicesSubtitleFontSize.value
);

const servicesSubtitleFontWeight = computed<number>(
	() => props.fontWeights?.servicesSubtitle || 500
);

const mobileServicesDescriptionFontSize = computed<CssSize>(
	() => props.fontSizes?.servicesDescription?.mobile || '1.125rem'
);

const tabletServicesDescriptionFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesDescription?.tablet ||
		mobileServicesDescriptionFontSize.value
);

const desktopServicesDescriptionFontSize = computed<CssSize>(
	() =>
		props.fontSizes?.servicesDescription?.desktop ||
		tabletServicesDescriptionFontSize.value
);

const servicesDescriptionFontWeight = computed<number>(
	() => props.fontWeights?.servicesDescription || 400
);

const serviceBackgroundColor = computed<string>(() =>
	getCSSVar(props?.backgroundColor || styles.value.appearance.background)
);

const serviceTextColor = computed<string>(() =>
	getCSSVar(props?.textColor || styles.value.appearance.text)
);
</script>

<style lang="scss">
.service {
	display: flex;
	flex-wrap: nowrap;
	gap: v-bind(mobileServicesGapBetweenImageAndText);
	flex: 0 0 auto;
	background-color: v-bind(serviceBackgroundColor);
	padding-top: 2rem;
	padding-bottom: 2rem;
	align-items: center;
	flex-direction: column;

	@include screen-up(md) {
		gap: v-bind(tabletServicesGapBetweenImageAndText);
	}

	@include screen-up(lg) {
		padding-top: calc(v-bind(itemGap) / 2);
		padding-bottom: calc(v-bind(itemGap) / 2);
		flex-direction: row;
		gap: v-bind(desktopServicesGapBetweenImageAndText);

		&:first-child {
			padding-top: v-bind(itemGap);
		}

		&:last-child {
			padding-bottom: v-bind(itemGap);
		}
	}

	.service-image {
		object-fit: contain;

		@include screen-up(lg) {
			width: 42%;
		}
	}

	.service-text-container {
		gap: v-bind(mobileServicesGapBetweenTextItems);
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;

		@include screen-up(md) {
			gap: v-bind(tabletServicesGapBetweenTextItems);
		}

		@include screen-up(lg) {
			width: 58%;
			gap: v-bind(desktopServicesGapBetweenTextItems);
		}

		.service-title {
			color: v-bind(serviceTextColor);

			font-size: v-bind(mobileServicesTitleFontSize);
			font-weight: v-bind(servicesTitleFontWeight);

			@include screen-up(md) {
				font-size: v-bind(tabletServicesTitleFontSize);
			}

			@include screen-up(lg) {
				font-size: v-bind(desktopServicesTitleFontSize);
			}
		}

		.service-subtitle {
			color: var(--darkgray);
			font-size: v-bind(mobileServicesSubtitleFontSize);
			font-weight: v-bind(servicesSubtitleFontWeight);

			@include screen-up(md) {
				font-size: v-bind(tabletServicesSubtitleFontSize);
			}

			@include screen-up(lg) {
				font-size: v-bind(desktopServicesSubtitleFontSize);
			}
		}

		.service-description {
			color: v-bind(serviceTextColor);
			font-size: v-bind(mobileServicesDescriptionFontSize);
			font-weight: v-bind(servicesDescriptionFontWeight);

			@include screen-up(md) {
				font-size: v-bind(tabletServicesDescriptionFontSize);
			}

			@include screen-up(lg) {
				font-size: v-bind(desktopServicesDescriptionFontSize);
			}
		}

		.buttons-container {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 1rem;
			margin-top: 1rem;
		}
	}

	&.odd {
		@include screen-up(lg) {
			flex-direction: row-reverse;
		}
	}
}
</style>
