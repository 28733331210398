<template>
	<div
		class="services-listing"
		:class="{ container: shouldLimitWidthOnDesktop }"
	>
		<ServiceCard
			v-for="(service, index) in companyProvidedServices"
			:key="`${service.title}:service-${index}`"
			:is-odd-row="isOddRow(index + 1)"
			v-bind="service"
			:item-gap="itemGap"
			:text-color="textColor"
			:background-color="backgroundColor"
			:font-weights="service.fontWeights || fontWeights"
			:font-sizes="service.fontSizes || fontSizes"
			:gap-between-image-and-text="
				service.gapBetweenImageAndText || gapBetweenImageAndText
			"
		/>
	</div>
</template>

<script setup lang="ts">
import type { ServicesListingSection } from '@SHARED/core/entities/sections/ServicesListingSection';

import ServiceCard from '@/components/molecules/ServiceCard.vue';

defineOptions({ name: 'ServicesListing' });

const props = withDefaults(defineProps<ServicesListingSection['config']>(), {
	variant: 'even-odd',
	backgroundColor: null,
	itemGap: '6rem',
	textColor: null,
	fontSizes: null,
	fontWeights: null,
	companyProvidedServices: () => [],
	shouldLimitWidthOnDesktop: false,
	gapBetweenImageAndText: null
});

function isOddRow(rowNumber: number): boolean {
	if (props.variant === 'odd') return true;

	if (props.variant === 'odd-even') {
		return rowNumber % 2 !== 0;
	}

	if (props.variant !== 'even-odd') return false;

	return rowNumber % 2 === 0;
}
</script>

<style lang="scss" scoped>
.services-listing {
	display: flex;
	flex-direction: column;
}
</style>
